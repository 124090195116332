import React, { useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import InputText from '../../DevComponents/InputText'
import { ContratoCancelamentoInterface } from '../../ImportBackend/Interfaces/ContratoInterfaces'
import ComboBox from '../../DevComponents/ComboBox'
import { MotivoCancelamentoContratoType, MotivoCancelamentoContratoTypes } from '../../ImportBackend/types/ConstantesDataTypes'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import ClsValidacao from '../../Utils/ClsValidacao'
import { clsUtils } from 'zlib-utils'

interface PropsInterface {
  onCancelar: () => void
  idContrato: number
}

export default function ClientesCancelarContrato ( { onCancelar, idContrato }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const ResetDados: ContratoCancelamentoInterface = {
    dataCancelamento: clsFormatos.dataAtualFormatoAMD(),
    idContrato: idContrato,
    motivoCancelamento: '',
    vrExecutado: 0
  }

  const [dados, setDados] = useState<ContratoCancelamentoInterface>( ResetDados )
  const [erros, setErros] = useState( {} )

  const abortController: AbortController = new AbortController()

  const clsApi = new BackEndAPI()
  const clsValidacao = new ClsValidacao()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    retorno = clsValidacao.naoVazio( 'motivoCancelamento', dados, erros, retorno )
    retorno = clsValidacao.eData( 'dataCancelamento', dados, erros, retorno )

    if ( retorno && dados.dataCancelamento > clsFormatos.dataAtualFormatoAMD() ) {
      erros = { ...erros, dataCancelamento: 'Não pode ser posterior a data atual!' }
      retorno = false
    }

    setErros( erros )

    return retorno

  }

  const onConfirmarCancelamentoContrato = () => {
    if ( validarDados() ) {
      cancelarContrato()
    }
  }

  const cancelarContrato = () => {

    const mutation: string = `
      cancelarContrato(dados: ${clsUtils.ConverterEmGql( dados )} )
        {
          ok
          mensagem
        }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'cancelarContrato', 'Cancelando Contrato', contexto ).then( rs => {
      if ( !rs.ok ) {
        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro ao Cancelar Contrato!!!',
          exibir: true,
          mensagem: 'Contrato Não Cancelado!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )
      } else {
        onCancelar()
      }
    } )

  }

  const pesquisarCancelamentoExecutado = () => {

    const query: string = `
      getContratoPorId (idContrato: ${idContrato}) {
        dataCancelamento
        idContrato
        motivoCancelamento
        vrExecutado
      }
    `

    clsApi.query<ContratoCancelamentoInterface>( query, 'getContratoPorId', 'Pesquisando Dados do Cancelamento...', contexto, abortController ).then( ( rs ) => {

      console.log('Resultado getContratoPorId',rs)

      setDados( { idContrato: idContrato, dataCancelamento: rs.dataCancelamento || '', motivoCancelamento: rs.motivoCancelamento || 'EE', vrExecutado: rs.vrExecutado || 0 } )

    } )

    return () => {

      abortController.abort()
    }
  }

  useEffect( () => {

    if ( idContrato && idContrato > 0 ) {
      pesquisarCancelamentoExecutado()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Dados para Cancelamento
                <Typography variant="body2" gutterBottom>
                  Informe o motivo e os dados para cancelamento
                </Typography>
              </Typography>

              <IconButton onClick={() => onCancelar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} md={2}>
              <InputText
                dados={dados}
                field='dataCancelamento'
                label='Data Cancelamento'
                setState={setDados}
                // disabled={[StatusForm.EXIBINDO, StatusForm.CANCELANDO].includes( statusForm )}
                erros={erros}
                tipo='date'
                mask='00/00/0000'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>

              <ComboBox
                dados={dados}
                label='Motivo'
                setState={setDados}
                opcoes={MotivoCancelamentoContratoTypes}
                campoDescricao='descricao'
                campoID='idMotivo'
                field='motivoCancelamento'
                valorPadraoCampoEmBranco={''}
                erros={erros}
                onChange={( e: any ) => { setDados( { ...dados, motivoCancelamento: e.idMotivo || '', vrExecutado: e.idMotivo !== MotivoCancelamentoContratoType.ESTORNO ? 0 : dados.vrExecutado || 0 } ) }}
              />

            </Grid>

            <Grid item xs={12} md={2} sx={{ pl: { md: 1 } }}>

              <InputText
                dados={dados}
                field='vrExecutado'
                label='Vr. Executado'
                setState={setDados}
                disabled={dados.motivoCancelamento !== MotivoCancelamentoContratoType.ESTORNO}
                erros={erros}
                tipo='currency'
                textAlign="right"
              />

            </Grid>

            <Grid item xs={12} md={2} sx={{ mt: 2, textAlign: 'right' }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ mt: 2, py: 1 }} onClick={() => onConfirmarCancelamentoContrato()}>Confirmar</Button>
            </Grid>

            <Grid item xs={12} md={2} sx={{ mt: 2, textAlign: 'right' }}>
              <Button variant='contained' startIcon={<CloseIcon />} sx={{ mt: 2, py: 1 }} onClick={() => onCancelar()}>Fechar</Button>
            </Grid>


          </Grid>
        </Paper>
        <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false} oque={['Dados', dados]} />
      </Container>

    </>
  )

}