import { Grid } from "@mui/material";
import ShowText from "../../DevComponents/ShowText";
import { ContratoInterface } from "../../ImportBackend/Interfaces/ContratoInterfaces";
import Condicional from "../../Layout/Condicional";
import ClientesContratos from "./ClientesContratos";

interface PropsInterface {
  dados: ContratoInterface
  onCancelar: () => void
}

export default function ContratosExibirContrato ( { dados, onCancelar }: PropsInterface ) {
  return (
    <>
      <Condicional condicao={dados.idCliente !== 0}>

        <Grid container>
          <Grid item xs={12} sm={3}>

            <ShowText
              dados={ (dados as any).Cliente}
              field='cpf'
              label='CPF'              
            />

          </Grid>

          <Grid item xs={12} sm={9}>

            <ShowText
              dados={ (dados as any).Cliente}
              field='nome'
              label='Cliente'              
            />

          </Grid>

        </Grid>

        <ClientesContratos onCancelar={onCancelar} idCliente={dados.idCliente} />

      </Condicional>
    </>
  )
}